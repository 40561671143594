import Vue from 'vue'
import App from './App.vue'
import { bus } from './eventBus.js'
import './assets/styles/mSpinnerSwirl.css'
import Vue2TouchEvents from 'vue2-touch-events'
import VueThreeSixty from 'vue-360'

import 'vue-360/dist/css/style.css'
import './assets/styles/360ViewerMods.css'
import router from './router'

Vue.use(VueThreeSixty)
Vue.use(Vue2TouchEvents)
Vue.config.productionTip = false

var store = {
  debug: true,
  settings: {
    showSoldWorks: false,
    showOnboarding: true,
    showPrices: false,
  },
  state: {
    items : [
      {
        id: 19,
        isSold: true,
        reverseScroll: true,
        name: "Blob 19",
        size: "15 x 19 x 19 (cm)",
        price: 9000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/19-blob",
        images: ["/images/blob-19.jpg"],
        prefix: "19-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 4,
        isSold: true,
        reverseScroll: true,
        name: "Blob 4",
        size: "23 x 12 x 12 (cm)",
        price: 10500,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/04-blob",
        images: ["/images/blob-4.jpg"],
        prefix: "04-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 14,
        isSold: true,
        reverseScroll: true,
        name: "Blob 14",
        size: "11 x 12 x 14 (cm)",
        price: 8000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/14-blob",
        images: ["/images/blob-14.jpg"],
        prefix: "14-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 6,
        isSold: true,
        reverseScroll: true,
        name: "Blob 6",
        size: "13 x 16 x 16 (cm)",
        price: 12000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/06-blob",
        images: ["/images/blob-6.jpg"],
        prefix: "06-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 5,
        isSold: true,
        reverseScroll: true,
        name: "Blob 5",
        size: "10 x 13 x 14 (cm)",
        price: 8000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/05-blob",
        images: ["/images/blob-5.jpg"],
        prefix: "05-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 18,
        isSold: true,
        reverseScroll: true,
        name: "Solid 2",
        size: "17 x 8 x 8 (cm)",
        price: 9000,
        year: "2023",
        description: "Technique: glass",
        path: "/images/works/18-blob",
        images: ["/images/blob-18.jpg"],
        prefix: "18-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 7,
        isSold: true,
        reverseScroll: true,
        name: "Blob 7",
        size: "11 x 13 x 14 (cm)",
        price: 8000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/07-blob",
        images: ["/images/blob-7.jpg"],
        prefix: "07-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 8,
        isSold: true,
        reverseScroll: true,
        name: "Blob 8",
        size: "15 x 16 x 16 (cm)",
        price: 12000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/08-blob",
        images: ["/images/blob-8.jpg"],
        prefix: "08-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 1,
        isSold: true,
        reverseScroll: true,
        name: "Blob 1",
        size: "21 x 13 x 12 (cm)",
        price: 0,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/01-blob",
        images: ["/images/blob-1.jpg"],
        prefix: "01-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 9,
        isSold: true,
        reverseScroll: true,
        name: "Blob 9",
        size: "14 x 13 x 15 (cm)",
        price: 8000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/09-blob",
        images: ["/images/blob-9.jpg"],
        prefix: "09-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 10,
        isSold: true,
        reverseScroll: true,
        name: "Blob 10",
        size: "14 x 12 x 11 (cm)",
        price: 10000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/10-blob",
        images: ["/images/blob-10.jpg"],
        prefix: "10-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 11,
        isSold: true,
        reverseScroll: true,
        name: "Blob 11",
        size: "15 x 15 x 16 (cm)",
        price: 12000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/11-blob",
        images: ["/images/blob-11.jpg"],
        prefix: "11-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 2,
        isSold: false,
        reverseScroll: true,
        name: "Blob 2",
        size: "22 x 11 x 12 (cm)",
        price: 12500,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/02-blob",
        images: ["/images/blob-2.jpg"],
        prefix: "02-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 13,
        isSold: true,
        reverseScroll: true,
        name: "Blob 13",
        size: "13 x 10 x 11 (cm)",
        price: 10000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/13-blob",
        images: ["/images/blob-13.jpg"],
        prefix: "13-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 12,
        isSold: true,
        reverseScroll: true,
        name: "Blob 12",
        size: "10 x 12 x 14 (cm)",
        price: 8000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/12-blob",
        images: ["/images/blob-12.jpg"],
        prefix: "12-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 15,
        isSold: true,
        reverseScroll: true,
        name: "Blob 15",
        size: "16 x 10 x 10 (cm)",
        price: 10000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/15-blob",
        images: ["/images/blob-15.jpg"],
        prefix: "15-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 16,
        isSold: true,
        reverseScroll: true,
        name: "Blob 16",
        size: "12 x 12 x 14 (cm)",
        price: 0,
        year: "2023",
        description: "Technique: glass",
        path: "/images/works/16-blob",
        images: ["/images/blob-16.jpg"],
        prefix: "16-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 3,
        isSold: true,
        reverseScroll: true,
        name: "Blob 3",
        size: "21.5 x 10 x 11 (cm)",
        price: 9000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/03-blob",
        images: ["/images/blob-3.jpg"],
        prefix: "03-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 17,
        isSold: true,
        reverseScroll: true,
        name: "Blob 17",
        size: "13 x 10 x 10 (cm)",
        price: 8000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/17-blob",
        images: ["/images/blob-17.jpg"],
        prefix: "17-blob-",
        extension: "jpg",
        amount: 32
      },
      {
        id: 20,
        isSold: true,
        reverseScroll: true,
        name: "Blob 20",
        size: "19 x 9 x 11 (cm)",
        price: 9000,
        year: "2023",
        description: "Technique: silvered glass",
        path: "/images/works/20-blob",
        images: ["/images/blob-20.jpg"],
        prefix: "20-blob-",
        extension: "jpg",
        amount: 32
      },
    ],
    selectedItem: 0,
    infoViewed: false
  },
  setSelectedItemById (id) {
    if (this.debug) console.log('setSelectedItemById triggered with', id)
    
    this.getItems().forEach((item, i) => {
      // the selectedItem value needs to be the array number and not the item id
      if (item.id == id) {
        this.state.selectedItem = i
        document.title = this.state.items[i].name + " | Glass party"
        bus.$emit('selectedItemChanged', {
          myPayload: i
        })
        return
      }
    })
  },
  increaseSlectedItem (isIncreasing) {
    if (this.debug) console.log('increase selected item', isIncreasing)
    if ((this.state.selectedItem > 0 && isIncreasing === false) || 
        (this.state.selectedItem < this.getItems().length-1 && isIncreasing === true)) {
      if (isIncreasing) {
        this.state.selectedItem++
      } else {
        this.state.selectedItem--
      }
      bus.$emit('selectedItemChanged', {
        myPayload: this.state.selectedItem
      })
    }
  },
  openBottomSheet () {
    if (this.debug) console.log('openBottomSheet triggered')
    bus.$emit('openBottomSheet', {
			item: this.state.selectedItem
		})
  },
  getItems() {
    // Filter on showSoldWorks setting
    //if (this.settings.showSoldWorks) {
      return this.state.items
    //} else {
    //  return this.state.items.filter(item => item.isSold == false)
    //}
  }
}

new Vue({
  render: h => h(App),
  router,
  created() {
    // Show/hide settings. Must be activated from startView to work, not from child views
    this.$root.sharedState.settings.showPrices = this.$route.query.showPrices ? true : false
    this.$root.sharedState.settings.showSoldWorks = this.$route.query.showSoldWorks ? true : false

    console.log(this.$root.sharedState.settings.showSoldWorks)
  },
  data: {
    privateState: {},
    sharedState: store
  }
}).$mount('#app')
