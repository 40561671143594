<template>
    <div>
        <div v-if="isMobile === true">
            <ul id="horizontalList" ref="horizontalList">
                <li class="listItem" v-for="item in this.getItems()" :key="item.model" :id="listItem+item.id" v-on:click="itemClicked">
                    <div class="listItem__content">
                        <h2><span v-bind:class="{ hidden: !item.isSold }" class="dot"></span>{{ item.name }}</h2>
                        <p>{{ item.year }}, {{ item.size }}</p>
                    </div>
                    <div class="listItem__img">
                        <img src="@/assets/chevron-up.svg" alt="chevron-up" />
                    </div>
                </li>
            </ul>
        </div>
        <div v-if="isMobile === false">
            <div class="desktopNav desktopNav--left" v-on:click="navLeft">
                <img src="@/assets/chevron-up.svg" class="desktopNav__img desktopNav__img--left" alt="chevron-up" />
            </div>
            <div class="desktopNav desktopNav--right" v-on:click="navRight">
                <img src="@/assets/chevron-up.svg" class="desktopNav__img desktopNav__img--right" alt="chevron-up" />
            </div>
            <ul id="horizontalList" class="ul--desktop" ref="horizontalList">
                <li class="listItem li--desktop" :key="getSelectedItem().id" v-on:click="itemClicked">
                    <div class="listItem__content">
                        <h2 class="h2--desktop"><span v-bind:class="{ hidden: !getSelectedItem().isSold }" class="dot"></span>{{ getSelectedItem().name }}</h2>
                        <p>{{ getSelectedItem().year }}, {{ getSelectedItem().size }}</p>
                    </div>
                    <div class="listItem__img listItem__img--desktop">
                        <img src="@/assets/chevron-up.svg" alt="chevron-up" />
                    </div>
                </li> 
            </ul>
        </div>
    </div>
</template>

<script>
import { bus } from '../eventBus.js'
export default {
    name: "HorizontalList",
    data: function() {
        return {
            listItem: "listitem-",
            isScrolling: null,
            isMobile: true
        }
    },
    destroyed () {
        document.getElementById("horizontalList").removeEventListener('scroll', this.handleScroll)
    },
    mounted() {
        document.getElementById("horizontalList").addEventListener('scroll', this.handleScroll)
        this.init()

        bus.$on('selectedItemChanged', () => {
            // Auto scroll to right item in mobile view
            if (this.isMobile) {
                this.$el.querySelector('#'+this.listItem+this.getSelectedItem().id).scrollIntoView({
                    behavior: "auto", block: "center", inline: "center"})
            }
        })
    },
    methods: {
        init() {
            // Check device type
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.screen.width <= 640) {
                this.isMobile = true
            } else {
                this.isMobile = false
                window.addEventListener("keyup", function(e) {
                    if(e.keyCode == 37) {
                        this.navLeft()
                    } else if(e.keyCode == 39) {
                        this.navRight()
                    }
                }.bind(this))
            }
        },
        getItems() {
            return this.$root.$data.sharedState.getItems()
        },
        navLeft() {
            const prevItem = this.getPrevoiusItem()
            if (!prevItem) { return } 
            this.$router.push({ params: { itemId:  prevItem.id }, hash: this.getHashString(prevItem.name) })
        },
        navRight() {
            const nextItem = this.getNextItem()
            if (!nextItem) { return } 
            this.$router.push({ params: { itemId:  nextItem.id}, hash: this.getHashString(nextItem.name) })
            
        },
        getSelectedItem() {
            const state = this.$root.$data.sharedState.state
            return this.getItems()[state.selectedItem]
        },
        getNextItem() {
            const state = this.$root.$data.sharedState.state
            const items = this.getItems()
            if (items[state.selectedItem+1] !== undefined) {
                return items[state.selectedItem+1]
            } else {
                null
            }
        },
        getPrevoiusItem() {
            const state = this.$root.$data.sharedState.state
            const items = this.getItems()
            if (items[state.selectedItem-1] !== undefined) {
                return items[state.selectedItem-1]
            } else {
                return null
            }
        },
        getHashString(s) {
            return '#' + s.replace(/[^a-z0-9]/gi, '-').toLowerCase()
        },
        itemClicked: function () {
            this.$root.$data.sharedState.openBottomSheet()
        },
        handleScroll() {
            const items = this.getItems()
            const router = this.$router
            const getHashString = this.getHashString
            const currentItemId = this.getSelectedItem().id
            window.clearTimeout(this.isScrolling)
            this.isScrolling = setTimeout(function() {
                // Select active model
                var st = document.getElementById('horizontalList').firstChild.getBoundingClientRect().x
                const childWidth = 320
                const selectedModel = Math.abs(Math.round(st / childWidth))
                const nextItem = items[selectedModel]
                if (currentItemId != nextItem.id) {
                    router.push({ params: { itemId: nextItem.id}, hash: getHashString(nextItem.name) })
                }
        }, 66)
        }
    }
}
</script>

<style scoped lang="scss">
ul {
    position: fixed; 
    bottom: 8px;
    right: 0;
    left: 0;
    height: 70px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 0;
}
li {
    width: 300px;
    height: 55px;
    background: #FFFDF9;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: inline-block; 
    scroll-snap-align: center;
    scroll-snap-stop: always;
    margin: 0 10px 0 10px;
    top: 0;

    &:first-child {
        margin-left: 37px;
    }
    &:last-child {
        margin-right: 37px;
    }
}
.ul--desktop {
    left: 50%;
    margin-left: -240px;
    height: 75px;
}
.li--desktop {
    width: 400px;
    height: 70px;
}
.h2--desktop {
    padding: 14px 16px 4px 16px;
    font-size: 20px;
}
.listItem__img--desktop {
    margin-top: 8px;
}
h2 {
    margin: 0;
    padding: 10px 16px 0 16px;
    font-family: boldKlav;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    display: table;
}
p {
    margin: 0;
    margin-top: 5px;
    padding: 0px 16px;
    display: table;
    font-family: regularKlav;
    font-style: normal;
    font-weight: regular;
}
.listItem__content {
    height: 100%;
    float: left;
}
.listItem__img {
    height: 100%;
    float: right;
    padding: 14px;
}
ul::-webkit-scrollbar {
  display: none;
}
.dot {
    height: 8px;
    width: 8px;
    margin-bottom: 1px;
    margin-right: 5px;
    background-color: #FF6060;
    border-radius: 50%;
    display: inline-block;
}
.hidden { display: none; }

.desktopNav {
    height: 92px;
    width: 92px;
    position: absolute;
    top: 50%;
    margin-top: -46px;
    border-radius: 50%;
    display: inline-block;
    background: #FFFDF9;
    box-shadow:  0px 1px 1px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.desktopNav--left {
    left: 8%;
}
.desktopNav--right {
    right: 8%;
}
.desktopNav__img {
    height: 64px;
    padding: 14px;
}
.desktopNav__img--right {
    transform: rotate(90deg);
}
.desktopNav__img--left {
    transform: rotate(-90deg);
}
.hidden { display: none; }
</style>
