<template>
  <div class="viewer">
    <PictureViewer />
    <HorizontalList />
    <BottomSheet />
  </div>
</template>

<script>
// @ is an alias to /src
import PictureViewer from '@/components/PictureViewer.vue'
import HorizontalList from '@/components/HorizontalList.vue'
import BottomSheet from '@/components/BottomSheet.vue'

export default {
  name: 'ItemViewer',
  components: {
    HorizontalList,
    BottomSheet,
    PictureViewer
  },
  mounted() {
    if (this.$route.params.itemId) {
      this.$root.$data.sharedState.setSelectedItemById(this.$route.params.itemId)
    }
  },
  watch: {
    $route(to) {
      this.$root.$data.sharedState.setSelectedItemById(to.params.itemId)
    }
  }
}
</script>
