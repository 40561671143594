<template>
  <div class="showViewer">
    <ul class="showList" ref="showList">
      <li class="showItem" v-for="item in $root.$data.sharedState.getItems()" 
        :key="item.model" 
        v-bind:class="{'showItem--sold': item.isSold }">
        <router-link v-bind:to="getItemViewerPath(item.id, item.name)" class="showItem__content">
          <img v-bind:src="item.images[0]" :key="item.id" />
        </router-link>
      </li>
    </ul>
    <div class="about"><a href="/about">About GLASS PARTY</a></div>
    <div class="about"><a href="https://www.benjaminuggla.com/kontakt.html">About the artist</a></div>
  </div>
</template>

<script>

export default {
  name: 'ShowViewer',
  components: {
  },
  mounted() {
    document.title = "Glass party"
  },
  methods: {
    getItemViewerPath(id, name) {
      return '/show/' + this.$route.params.showId + '/' + id + this.getHashString(name)
    },
    getHashString(s) {
      return '#' + s.replace(/[^a-z0-9]/gi, '-').toLowerCase()
    },
  }
}
</script>

<style scoped>
.about {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0 0px;
    justify-content: center;
    margin-bottom: 32px;
    font-family: regularKlav;
}
.about:last-child {
  margin-bottom: 50px;
  padding-bottom: 60px;
}
.showItem--sold:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 0;
    border-color: transparent #ff6060 transparent transparent;
    right: 0;
    top: 0;
    position: absolute;
}
.showViewer {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-top: 50px;
}
.showList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 0px;
  justify-content: center;
  margin-bottom: 100px;
}
.showItem {
  overflow: hidden;
  flex: 0 0 auto;
    margin: 4px;
  position: relative;
  width: calc(33vw - 16px);
  line-height: calc(33vw - 16px);
}
.showItem img {
  object-fit: cover;
  object-position: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
</style>