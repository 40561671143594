<template>
    <div>
        <div ref="threeSixtyInfo" class="threesixtyInfo">
            <img src="@/assets/360.svg" alt="360-rotation-info" />
        </div>
        <vue-three-sixty 
            :key="componentKey"
            class="pictureViewerWrapper fade-in"
            :amount=this.getAmount()
            :imagePath=this.getPath()
            :fileName=this.getFileName()
            :spinReverse=this.getReverseSetting()
            />
    </div>
</template>

<script>
import { bus } from '../eventBus.js'
export default {
    name: "PictureViewer",
    props: {
        exampleProp: String,
    },
    data: function() {
        return {
            componentKey: 0,
            isMobile: false
        }
    },
    mounted() {
        this.init()

        bus.$on('selectedItemChanged', () => {
            var canvas = document.getElementsByClassName("v360-image-container")[0]
            canvas.style = "display: none;"
            var context = canvas.getContext('2d')
            context.clearRect(0, 0, canvas.width, canvas.height)

            if (/iphone|ipad|ipod|safari|/i.test(navigator.userAgent.toLowerCase())) {
                // This is needed, else application will stop working
                // in Safari due to large memory usage
                canvas.width = canvas.height = 0
                // Refresh component
                this.componentKey += 1
            }
        })
    },
    methods: {
        init() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.screen.width <= 640) {
                this.isMobile = true;
            }
            const env = this
            // Remove 360 degrees info block
            if (!this.$root.$data.sharedState.state.infoViewed) {
                setTimeout(function() {
                    env.$refs.threeSixtyInfo.style = "display: none;"
                }, 4000)
                this.$root.$data.sharedState.state.infoViewed = true
            } else {
                env.$refs.threeSixtyInfo.style = "display: none;"
            }
        },
        getSelectedItem() {
            const state = this.$root.$data.sharedState.state
            return this.$root.$data.sharedState.getItems()[state.selectedItem]
        },
        getPath() {
            return this.getSelectedItem().path
        },
        getFileName() {
            return this.getSelectedItem().prefix + "{index}." + this.getSelectedItem().extension + "?v" + this.componentKey
        },
        getReverseSetting() {
            return this.getSelectedItem().reverseScroll
        },
        getAmount() {
            return this.getSelectedItem().amount
        }
    }
}
</script>

<style scoped lang="scss">
.pictureViewerWrapper {
    height: 100%;
    width: 100%;
    position: absolute;
}
.threesixtyInfo {
    position: fixed;
    z-index: 1000;
    margin-left: -48px;
    left: 50%;
    top: 20%;
}

.fade-in {
animation: fadeIn ease 500ms;
-webkit-animation: fadeIn ease 500ms;
-moz-animation: fadeIn ease 500ms;
-o-animation: fadeIn ease 500ms;
-ms-animation: fadeIn ease 500ms;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.fade-out {
animation: fadeOut ease 100ms;
-webkit-animation: fadeOut ease 100ms;
-moz-animation: fadeOut ease 100ms;
-o-animation: fadeOut ease 100ms;
-ms-animation: fadeOut ease 100ms;
}
@keyframes fadeOut {
0% {opacity:1;}
100% {opacity:0;}
}

@-moz-keyframes fadeOut {
0% {opacity:1;}
100% {opacity:0;}
}

@-webkit-keyframes fadeOut {
0% {opacity:1;}
100% {opacity:0;}
}

@-o-keyframes fadeOut {
0% {opacity:1;}
100% {opacity:0;}
}

@-ms-keyframes fadeOut {
0% {opacity:1;}
100% {opacity:0;}
}
</style>
