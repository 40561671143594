<template>
    <div class="header">
        <div :class="{ 'hidden': !visible, 'btnBack': true }" >
            <router-link to="/show/glass-party">
                <img src="@/assets/chevron-plus-left.svg" class="btnBack__img--left" alt="chevron-left" />
            </router-link>
        </div>
        <h1><router-link to="/show/glass-party">GLASS PARTY</router-link></h1>
    </div>
</template>

<script>
export default {
    name: "Header",
    props: {
        exampleProp: String,
    },
    data: function() {
        return {
        }
    },
    computed: {
        visible: function() {
            return this.currentRoutedName === "ItemViewer"
            || this.currentRoutedName === "About"
        },
        currentRoutedName: function() {
            return this.$route.name
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
        }
    },
}
</script>

<style scoped lang="scss">
.hidden { visibility: hidden; }
h1 {
    margin: 0;
    padding: 10px 16px 0 16px;
    font-family: boldKlav;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
}
a {
    text-decoration: none;
    color: black;
}
.header {
    position: absolute;
    z-index: 15;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
}
.btnBack {
    position: absolute;
    padding: 9px 16px 0 16px;
}
</style>
