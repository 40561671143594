<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: regularKlav;
  src: url(/KlavikaWebBasicRegular.woff2);
}
@font-face {
  font-family: boldKlav;
  src: url(/KlavikaWebBasicBold.woff2);
}
body {
  margin: 0;
  background: linear-gradient(180deg, #D1D0CB 0%, #FFFFFF 0.01%, rgba(185, 168, 135, 0.51) 100%);
  overscroll-behavior: none;
}
canvas { 
  display: block;
}
body::-webkit-scrollbar {
  display: none;
}
html, body {margin: 0; height: 100%; overflow: hidden; font-family: regularKlav;}
</style>