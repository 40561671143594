<template>
    <div class="bottom-sheet" 
        ref="bottomSheet"
        v-touch:start="onSwipeStart" 
        v-touch:end="onSwipeEnd" 
        v-touch:swipe.down="onSwipeDown" 
        v-bind:class="{ 'hidden': !visible, 'bottom-sheet--desktop': !isMobile }"
        v-bind:style="[ marginTop !== null ? {top: marginTop + 'px'} : {}]">
        
        <div class="bottom-sheet__top--wrapper" 
        v-touch:moving="onPanVertical" 
        v-touch:start="onPanStart"
        v-touch:end="onPanEnd">
            <div class="bottom-sheet__top--handle" />
            <div class="bottom-sheet__top--cross" v-on:click="closeSheet">
                <img src="@/assets/cross.svg" alt="cross" />
            </div>
        </div>
        <div class="bottom-sheet__content" ref="content">
            <h2>{{ this.getSelectedItem().name }}</h2>
            <p>{{ this.getSelectedItem().year }}, {{ this.getSelectedItem().size }}</p>
            <p>{{ this.getSelectedItem().description }}</p>
            <p class="sold" v-bind:class="{ hidden: !this.getSelectedItem().isSold }">Artwork is sold</p>
            <p v-if="$root.$data.sharedState.settings.showPrices && !this.getSelectedItem().isSold"><b>Price:</b> {{ this.getSelectedItem().price }} SEK</p>

            <p v-bind:class="{ hidden: this.getSelectedItem().isSold }">Inqueries via <a href = "mailto: info@benjaminuggla.com">Email</a></p>

            <img v-for="img in this.getSelectedItem().images" :key="img" v-bind:src="img" />
        </div>
    </div>
</template>

<script>
import { bus } from '../eventBus.js'

export default {
    name: "Header",
    props: {
        exampleProp: String,
    },
    data: function() {
        return {
            visible: false,
            marginTop: null,
            swipeThreshold: 200,
            panStart: Date.now(),
            panDuration: 0,
            isPanning: false,
            swipeStart: null,
            swipeDuration: 0,
            bottomSheetVH: 0,
            minBottomsheetVH: 50,
            maxBottomsheetVH: 95,
            isMobile: false
        }
    },
    mounted() {
        this.init()
        const env = this
        this.bottomSheetVisible(true)
        bus.$on('openBottomSheet', () => {
            this.marginTop = null
            env.setBottomsheetHeight()
            env.onSwipeUp()
        })
    },
    methods: {
        init() {
            this.$refs.bottomSheet.style.transition = "transform 500ms"
            // Add arrow support for desktop
            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.screen.width <= 640 ) {
                this.isMobile = true;
            }
            // Add navigation key up/down
            if (!this.isMobile) {
                window.addEventListener("keyup", function(e) {
                    if(e.keyCode == 38) {
                        this.marginTop = null
                        this.setBottomsheetHeight()
                        this.onSwipeUp()
                    } else if(e.keyCode == 40) {
                        this.closeSheet()
                    }
                }.bind(this))
            }
        },
        getSelectedItem() {
            const state = this.$root.$data.sharedState.state
            return this.$root.$data.sharedState.getItems()[state.selectedItem]
        },
        closeSheet() {
            this.bottomSheetVisible(false)
        },
        onSwipeDown(direction) {
            var contentScrolledToTop = this.$refs.content.scrollTop == 0
            if (direction === "bottom" && 
            (this.swipeDuration < this.swipeThreshold) && (contentScrolledToTop || this.isPanning)) {
                this.bottomSheetVisible(false)
            }
            this.isPanning = false
            this.swipeDuration = 0
        },
        onSwipeUp() {
            this.visible = true
            this.bottomSheetVisible(true)
        },
        setBottomsheetHeight() {
            const marginHack = 17
            const element = this.$refs.bottomSheet
            let height = Math.round(element.offsetHeight / window.innerHeight * 100)
            if (height < this.minBottomsheetVH) {
                height = this.minBottomsheetVH
            } else if (height > this.maxBottomsheetVH) {
                height = this.maxBottomsheetVH
            }

            // Temp fix
            // Always set maximum height
            this.bottomSheetVH =  this.maxBottomsheetVH
            element.style.height = this.bottomSheetVH + marginHack + "vh"
        },
        bottomSheetVisible(open) {
            //const marginHack = 20
            this.$refs.bottomSheet.style.webkitTransform = "translateY(-" + (open ? this.bottomSheetVH + "vh" : "0") + ")"
        },
        onSwipeStart() {
            this.swipeStart = Date.now()
        },
        onSwipeEnd() {
            this.swipeDuration = Date.now() - this.swipeStart
        },
        onPanVertical: function (e) {
            console.log("panning")
            const deltaY = e.targetTouches[0].clientY
            //const originalMargin = window.innerHeight * -((this.bottomSheetVH/100)-1)
            const change = this.deltaYStart - deltaY
            console.log(change)
            if (change < 0) {
            //if (change < 0 || originalMargin > this.marginTop) {
                //this.marginTop = window.innerHeight - change
                this.marginTop = -change
                console.log(this.marginTop)
            }
        },
        onPanStart: function(e) {
            this.isPanning = true
            this.deltaYStart = e.targetTouches[0].clientY + 10
            this.panStart = Date.now()
        },
        onPanEnd() {
            this.panDuration = Date.now() - this.panStart

            const originalMargin = window.innerHeight * 0.25
            if(this.marginTop > window.innerHeight + originalMargin) {
                this.bottomSheetVisible(false)
            } else {
                this.marginTop = null
            }
        }
    }
}
</script>

<style scoped lang="scss">
.hidden { visibility: hidden; }
.bottom-sheet {
    z-index: 1000;
    margin-top: 100vh;
    width: 100%;
    background: white;
    border-radius: 32px 32px 0 0;
    position: fixed;
    right: 0;
    left: 0;
    text-align: center;

    font-family: regularKlav;
    font-style: normal;
    font-weight: regular;

    h2 {
        margin-top: 0;
    }
    img {
        width: 100%;
        //margin-bottom: 50px;
    }
}
.bottom-sheet--desktop {
    margin-left: -320px;
    width: 640px;
    left: 50%;
}

.bottom-sheet__content {
    text-align: left;
    margin: 0 24px;
    overflow-y: scroll;
    height: 100%;
}

.bottom-sheet__top--handle {
    width: 24px;
    height: 4px;
    background: grey;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 5px;
}

.bottom-sheet__top--wrapper {
    width: 100%;
    height: 30px;
}

.bottom-sheet__top--cross {
    height: 100%;
    float: right;
    padding: 12px;
    position: absolute;
    right: 16px;
    top: 27px;
}
.sold { color: #FF6060; }
.hidden { display: none; }
</style>
